<script>
import PageHeader from './PageHeader'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import SearchBox from '@/components/SearchBox/SearchBox'
import detectModule from '@/mixins/detectModule'
import participant from '@/mixins/queries/participant'
import sites from '@/mixins/queries/sites'
import { getProtocolVersion, isVisitInstanceInProgress, getVisitCompletionPercent } from '@/utils/visit'

export default {
  components: {
    PageHeader,
    SkeletonBox,
    SearchBox
  },
  mixins: [
    detectModule,
    participant,
    sites
  ],
  props: {
    visit: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * A router object to send a user back to the participant's Visit Schedule/Participant Detail screen.
     * @returns {object} - a router link object
     */
    backToParticipant() {
      return {
        text: this.fullNameOrPPMIPlusId,
        route: {
          name: this.isEDCActive ? 'visitSchedule' : 'participantDetails',
          params: { ...this.$route.params }
        }
      }
    },

    /**
     * Get the visit name to display in the header.
     * If the visit name is available, display "Visit" as a fallback
     * @returns {string}
     */
    visitName() {
      return this.visit.visitTemplate.name ?? 'Visit'
    },

    /**
     * Get the complete % for this visit.
     */
    percentComplete() {
      return this.visit.formInstances.length ? getVisitCompletionPercent(this.visit.formInstances) : 0
    },

    /**
     * Get the current protocol version.
     * @returns {string}
     */
    protocolVersion() {
      if (this.visit) {
        return getProtocolVersion(this.visit)
      }

      return ''
    },

    showActions() {
      return this.isEDCActive && isVisitInstanceInProgress(this.visit.visitInstance)
    }
  }
}
</script>

<template>
  <PageHeader
    class="visit-detail-header"
    :title="visitName"
    :back-link="backToParticipant"
  >
    <template v-slot:title>
      <h1 class="visit-name">
        <SkeletonBox
          v-if="loading"
          width="12rem"
        />
        <span v-else>{{ visitName }}</span>
      </h1>
      <p
        v-if="isEDCActive"
        class="visit-info"
      >
        <SkeletonBox
          v-if="loading"
          width="15rem"
        />
        <span v-else>
          {{ percentComplete }}% Complete • Protocol Version {{ protocolVersion }}
        </span>
      </p>
    </template>
    <template v-if="isCTMSActive">
      <dl class="participant-details">
        <div class="participant-details__field">
          <dt>Progress</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>{{ percentComplete }}% Complete</span>
          </dd>
        </div>
        <div class="participant-details__field">
          <dt>Protocol</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>Protocol Version {{ protocolVersion }}</span>
          </dd>
        </div>
        <div class="participant-details__field">
          <dt>Site</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>{{ getSiteName(participant.siteId) }}</span>
          </dd>
        </div>
      </dl>
    </template>
    <template v-slot:actions>
      <SearchBox
        placeholder="Find a CRF"
        @input="$emit('search-change', $event)"
      />
      <el-button
        v-if="showActions"
        class="add-forms-button"
        type="menu"
        @click="$emit('add-forms')"
      >
        <SvgIcon
          name="add"
          width="1rem"
          height="1rem"
        />
      </el-button>
    </template>
  </PageHeader>
</template>

<style lang="scss">
  .header.visit-detail-header {
    padding: 1rem;
    padding-top: .5rem;
    border-bottom: none;
    margin: -1.5rem;
    margin-bottom: 1.5rem;
    background-color: $white-matter;
    // sticky position header
    position: sticky;
    top: -1.5rem; // reset from padding wrap
    @include elevate(sm);
    border-radius: 0;

    .visit-name {
      @include text-style('title', 'large', 'bold');
    }

    &__title-wrap {
      align-items: center;
    }

    .visit-info {
      margin: 0;
      color: $black;
      @include text-style('interface', 'small', 'normal');
    }

    .back-link {
      margin-left: -.5rem;
    }

    .search-box input {
      max-width: 12rem;
      transition: all .3s;

      &:focus,
      &:active {
        max-width: 17rem;
      }
    }
  }

  #app .add-forms-button {
    span {
      display: inline;
    }
  }
</style>
