<script>
import VisitDetailHeader from '@/components/PageHeader/VisitDetailHeader'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import RequiredCRFBanner from '@/components/Banners/RequiredCRFBanner'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import Dropdown from '@/components/Dropdown/Dropdown'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import CRFList from '@/components/CRFList/CRFList'
import Modal from '@/components/Modal/Modal'
import SkipVisitModal from '@/components/SkipVisitModal/SkipVisitModal'
import CompleteVisitModal from '@/components/CompleteVisitModal/CompleteVisitModal'
import AddFormsModal from '@/components/AddFormsModal/AddFormsModal'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import detectModule from '@/mixins/detectModule'
import currentUser from '@/mixins/queries/currentUser'
import updateVisitInstance from '@/mixins/mutations/updateVisitInstance'
import deleteVisitInstance from '@/mixins/mutations/deleteVisitInstance'
import {
  getProtocolVersion,
  isVisitInstanceInProgress,
  getVisitInstanceDisplayDate,
  getBlockingForms } from '@/utils/visit'
import { FormStatus, CTMSPermission } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { mapState } from 'vuex'
import { userHasStudyPermission } from '../../utils/user'

export default {
  components: {
    VisitDetailHeader,
    ContextMenu,
    RequiredCRFBanner,
    SkeletonBox,
    Dropdown,
    BfDatePicker,
    CRFList,
    Modal,
    CompleteVisitModal,
    AddFormsModal,
    SkipVisitModal
  },
  mixins: [
    detectModule,
    currentUser,
    updateVisitInstance,
    deleteVisitInstance
  ],
  apollo: {
    visitData() {
      return {
        query: GET_VISIT_QUERY,
        variables: {
          visitInstanceId: this.$route.params.visitInstanceId
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'VisitDetail.vue visit query')
        }
      }
    }
  },
  data () {
    return {
      visitData: {
        formInstances: [],
        formVersions: [],
        visitTemplateForms: [],
        visitInstance: {},
        visitTemplate: {}
      },
      searchString: '',
      updatedStartDate: '',
      approveVisitModalVisible: false,
      confirmApproveVisitModalVisible: false,
      addFormsModalVisible: false,
      displayDidNotCompleteVisitModal: false
    }
  },
  computed: {
    ...mapState(['isOnline']),

    // get an array of unadded forms to be used in the add forms modal
    // We allow any form to be added to the visit, whether it's optional or not. This is to account for schedule
    // changes between when the visit was started and when it was completed. If a form is now required, it will
    // be added as normal, then no longer be able to be removed.
    unaddedForms() {
      return this.visitData.visitTemplateForms
        .filter(visitTemplateForm =>
          !this.visitData.formInstances.some(formInstance => formInstance.formVersion.code === visitTemplateForm.code)
        )
    },

    showVisitActions() {
      return this.isEDCActive && isVisitInstanceInProgress(this.visitData.visitInstance)
    },

    canNavigateForms() {
      return this.isEDCActive || userHasStudyPermission(
        this.currentUser,
        this.$route.params.studyId,
        CTMSPermission.VIEW_FORMS)
    },

    /**
     * Get the display date for the current visit.
     * This will display the start date if the visit is not completed.
     * Will display the completed date after a visit has been completed.
     * @returns {string} - a date string of a status label + "visit date"
     */
    visitStartDate() {
      // ensure a visit instance exists.
      if (!this.visitData.visitInstance || !this.visitData.visitInstance.startDate) {
        return 'Not Started'
      }
      const statusLabel = isVisitInstanceInProgress(this.visitData.visitInstance)
        ? 'Started '
        : 'Completed '
      const dateDisplay = getVisitInstanceDisplayDate(this.visitData.visitInstance)
      return statusLabel.concat(dateDisplay)
    },

    canEditStartDate() {
      return this.isEDCActive && isVisitInstanceInProgress(this.visitData.visitInstance)
    },

    canClearStartDate() {
      return this.canEditStartDate && !this.visitHasData && this.visitData.visitInstance.startDate
    },

    protocolVersion() {
      if (this.visit) {
        return getProtocolVersion(this.visit)
      }

      return ''
    },

    consentForm() {
      const consentForm = this.visitData.formInstances.find(formInstance =>
        (formInstance.formVersion.behaviorType === 'informed_consent')
      )
      return consentForm || {}
    },

    /**
     * Return a "next up" formInstance of a form that is required to continue with the visit.
     * Retrieve all forms that block other forms (A form that must be completed to "unlock" others).
     * Return the first match.
     */
    requiredCRFToContinue() {
      const requiredCRFsToContinue = getBlockingForms(this.visitData)
      return requiredCRFsToContinue.length ? requiredCRFsToContinue[0] : null
    },

    /**
     * Show the Required CRF banner if a requiredCRF is found and the consent banner is not currently displayed.
     */
    showRequiredCRFBanner() {
      return this.isEDCActive && this.requiredCRFToContinue && !this.showConsentBanner
    },

    hasIncompleteForms() {
      return this.visitData.formInstances.some(formInstance => {
        if (formInstance.isOptional) {
          // if it is an optional form AND it is in progress, it is incomplete.
          return formInstance.status === FormStatus.IN_PROGRESS
        } else {
          // if it is a required form and it does not have a status of complete or skipped, it is incomplete
          return formInstance.status !== FormStatus.COMPLETE && formInstance.status !== FormStatus.NOT_COMPLETING
        }
      })
    },

    visitHasData() {
      return this.visitData.formInstances.some(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
    },

    /**
     * Check if the visit is skippable.
     * If a visit has been started but no data is associated with it (progress = 0),
     * it can be marked as "Did Not Complete"
     * @returns {boolean}
     */
    displayDidNotCompleteOption() {
      return this.visitData.visitTemplate.isSkippable && !this.visitHasData
    },

    hasContextOptions() {
      return this.displayDidNotCompleteOption
    },
    showActions() {
      return this.isEDCActive && isVisitInstanceInProgress(this.visitData.visitInstance)
    }
  },
  methods: {
    /**
     * Open the date picker to select a new visit start date.
     * Calls the openDatepicker method on the BfDatePicker component.
     * The selection will be picked up normally through the @change event.
     */
    pickNewStartDate() {
      this.$refs.startDatePicker.openDatepicker()
      if (this.$refs.startDateOptions) {
        this.$refs.startDateOptions.toggle()
      }
    },

    handleCompleteVisitClick() {
      if (!this.isOnline) { return } // cancel action if offline
      if (this.hasIncompleteForms) {
        this.confirmApproveVisitModalVisible = true
      } else {
        this.approveVisitModalVisible = true
      }
    },

    handleSkipVisitClose() {
      this.$router.push({
        name: this.isEDCActive ? 'visitSchedule' : 'participantDetails',
        params: { ...this.$route.params }
      })
    }
  }
}
</script>

<template>
  <div class="visit-detail">
    <VisitDetailHeader
      :visit="visitData"
      :loading="$apollo.queries.visitData.loading"
      @search-change="searchString = $event"
      @add-forms="addFormsModalVisible = true"
    />

    <RequiredCRFBanner
      v-if="showRequiredCRFBanner"
      :required-forms="[requiredCRFToContinue]"
    />

    <header class="forms-header">
      <div class="visit-info">
        <h2>Forms</h2>
        <SkeletonBox
          v-if="$apollo.queries.visitData.loading || updatingVisitInstance"
          width="15rem"
        />
        <p
          v-else
          class="visit-date"
          :class="canEditStartDate && 'editable'"
        >
          {{ visitStartDate }}
          <Dropdown
            v-if="canClearStartDate"
            ref="startDateOptions"
            title="Edit Start Date"
            class="start-date-options"
            :close-on-click-outside="true"
          >
            <el-dropdown-item @click.native="pickNewStartDate">
              Change Start Date
            </el-dropdown-item>
            <el-dropdown-item @click.native="clearVisitStartDate(visitData.visitInstance)">
              Clear Start Date
            </el-dropdown-item>
          </Dropdown>
          <el-button
            v-else-if="canEditStartDate"
            type="ghost"
            @click="pickNewStartDate"
          >
            Edit Start Date
          </el-button>
          <BfDatePicker
            v-if="canEditStartDate"
            ref="startDatePicker"
            v-model="updatedStartDate"
            class="start-date-picker"
            popper-class="start-date-popper"
            @change="newValue => modifyVisitStartDate(visitData.visitInstance, newValue)"
          />
        </p>
      </div>

      <div
        v-if="showVisitActions"
        class="visit-actions"
      >
        <el-button
          type="primary"
          :disabled="!isOnline"
          @click="handleCompleteVisitClick"
        >
          Complete Visit
        </el-button>
        <ContextMenu v-if="hasContextOptions">
          <el-dropdown-item
            v-if="displayDidNotCompleteOption"
            @click.native="displayDidNotCompleteVisitModal = true"
          >
            Did Not Complete
          </el-dropdown-item>
        </ContextMenu>
      </div>
    </header>

    <div class="visit-forms">
      <CRFList
        :visit-instance-id="visitData.visitInstance.id"
        :form-versions="visitData.formVersions"
        :form-instances="visitData.formInstances"
        :can-navigate-forms="canNavigateForms"
        :can-modify-forms="showActions"
        :show-search-bar="false"
        :loading="$apollo.queries.visitData.loading"
        :external-search-string="searchString"
        :visit-status="visitData.visitInstance && visitData.visitInstance.status"
        :visit="visitData"
      />
    </div>
    <Modal
      v-if="confirmApproveVisitModalVisible"
      @close="confirmApproveVisitModalVisible = false"
    >
      <template v-slot:title>
        Visit has incomplete forms
      </template>
      <template v-slot:content>
        <p>
          All forms must be completed before this visit can be completed.
        </p>
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="confirmApproveVisitModalVisible = false"
        >
          Return to forms
        </el-button>
      </template>
    </Modal>

    <SkipVisitModal
      v-if="displayDidNotCompleteVisitModal"
      :visit="visitData.visitTemplate"
      :visit-instance-id="visitData.visitInstance ? visitData.visitInstance.id : null"
      @close="handleSkipVisitClose"
    />

    <CompleteVisitModal
      v-if="approveVisitModalVisible"
      :visit-start-date="visitData.visitInstance.startDate"
      :visit="visitData"
      @close="approveVisitModalVisible = false"
    />

    <AddFormsModal
      v-if="addFormsModalVisible"
      :forms="unaddedForms"
      @close="addFormsModalVisible = false"
    />
  </div>
</template>

<style lang="scss">
  .visit-detail {
    .forms-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h2 {
        @include text-style('title', 'extra-large', 'bold');
        margin: 0;
      }

      p {
        margin: 0;
        @include text-style('interface', 'small', 'regular')
      }
    }

    .visit-info {
      position: relative;
    }

    /* offset the padding on the edit ghost button. */
    .visit-date.editable {
      margin-top: -.5rem;

      .start-date-options {
        padding: .5rem 0;
      }
    }

    .visit-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: .5rem;
    }

    .start-date-options {
      display: inline-block;
      color: $dopamine;
      @include text-weight('medium');

      svg {
        display: none;
      }

      .dropdown__content {
        margin-top: .5rem;
        margin-left: .5rem;
      }
    }

    .start-date-picker {
      position: absolute;
      bottom: 0;
      right: 6.5rem;
      width: 1px;

      input {
        height: 1px;
        width: 1px;
        padding: 0;
        background: transparent;
        border: none;
      }

      .icon-wrapper {
        display: none;
      }
    }
  }

  #app .visit-info .el-button--ghost {
    @include text-weight('medium');
  }
</style>
